import React, { useEffect, useState } from "react";
import './PatientsList.css';
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../URL";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

function PatientsList() {
    const [patientsList, setPatientsList] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    let navigate = useNavigate();
    const pagination = true;
    const paginationPageSize = 20;
    const paginationPageSizeSelector = [20, 50, 100];




    const [colDefs, setColDefs] = useState([
        {field: "id", hide: true},
        {field: "firstName", filter: true, floatingFilter: true},
        {field: "lastName", filter: true, floatingFilter: true},
        {field: "dateOfBirth", filter: true, floatingFilter: true},
        {field: "mobileNumber", filter: true, floatingFilter: true},
        {field: "email", filter: true, floatingFilter: true},
        {field: "accountType", hide: true},
        {field: "staffType", hide: true},
        {field: "status", hide: true},
        {field: "createdOn", filter: true, floatingFilter: true},
        {field: "address", filter: true, floatingFilter: true},
        {field: "gender", filter: true, floatingFilter: true}
    ]);

    useEffect(() => {
        const accountType = sessionStorage.getItem("accountType");
        if (accountType !== "staff") {
            toast.warning('You dont have access to this page.!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            navigate('/login');
        } else {
            fetchPatientsList();
        }
    }, []);

    const fetchPatientsList = () => {
        const token = sessionStorage.getItem("token");
        const requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json"
            }
        };

        fetch(API_BASE_URL+`/user/getPatientsList?token=${token}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setPatientsList(result.data);
            })
            .catch(error => console.error(error));
    };

    const handlePatientSelect = (patient) => {
        setSelectedPatient(patient);
    };


    const handleUpload = () => {
        const token = sessionStorage.getItem("token");

        const formData = new FormData();
        formData.append("token", token);
        formData.append("file", file);
        formData.append("fileName", fileName);
        formData.append("id", selectedPatient.id);

        // You can perform your upload logic here, e.g., send a POST request with formData
        fetch(API_BASE_URL+"/file/upload", {
            method: "POST",
            body: formData
        })
            .then(response => {
                // Handle response
                toast.success('File uploaded successful.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                //Reset the form
                setFile(null);
                setFileName("");
            })
            .catch(error => {
                // Handle error
                toast.warning('File upload failed.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            });
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleNameChange = (event) => {
        setFileName(event.target.value);
    };


    function addNewPatient() {
        navigate('/addNewPatient');
    }

    return (
        <div className="selectPatient">

            <div className="data-grid">
                <h3 className="patients-list-heading">Patients List</h3>
                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={{ width: '100%', height: '90%' }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={patientsList}
                        columnDefs={colDefs}
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                        // paginationPageSizeSelector={paginationPageSizeSelector}
                    />
                </div>
                {/*<button onClick={addNewPatient}>Add New Patient</button>*/}
            </div>

            {/*<div className="file-upload">*/}
            {/*    <input type="file" onChange={handleFileChange}/>*/}
            {/*    <input type="text" placeholder="Enter file name" value={fileName} onChange={handleNameChange}/>*/}
            {/*    <button onClick={handleUpload}>Upload</button>*/}
            {/*</div>*/}
        </div>
    );
}

export default PatientsList;