import React from "react";

const News = () => {
    return (
        <div>
            <p>News</p>
        </div>
    );
};

export default News;
