import React, { useCallback, useEffect } from "react";
import { navigate, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { API_BASE_URL } from "../URL";

function Portal() {
    const navigate = useNavigate();

    const showToast = useCallback((message, type) => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    }, []);

    useEffect(() => {
        const checkTokenValidity = async () => {
            const token = sessionStorage.getItem("token");
            if (!token) {
                navigate("/login");
                return;
            }

            try {
                const request = await fetch(API_BASE_URL + `/auth/isValidToken?token=${token}`);
                const response = await request.json();
                if (response.message === "Valid Token") {
                    if (sessionStorage.getItem("accountType") === "patient") {
                        console.log("User is patient at Portal");
                        navigate("/patientHomePage");
                    } else if (sessionStorage.getItem("accountType") === "staff") {
                        console.log("User is staff at Portal");
                        navigate("/staffHomePage");
                    }
                } else {
                    navigate("/login");
                    console.log("Token expired checked at Portal");
                    showToast("Token expired", "warning");

                }
            } catch (error) {
                console.error("Error checking token validity:", error);
                showToast("Error checking token validity", "warning");
                navigate("/login");
            }
        };

        checkTokenValidity();
    }, [navigate, showToast]);

    // return (
    //     <div>
    //         <p>Portal</p>
    //     </div>
    // );
}

export default Portal;
