import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import {Bounce, toast} from "react-toastify";
import {API_BASE_URL} from "../../URL";
import {AgGridReact} from "ag-grid-react";
import './AddFiles.css';


export default function AddFiles() {
    const [patientsList, setPatientsList] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    let navigate = useNavigate();
    const pagination = true;
    const paginationPageSize = 20;
    const paginationPageSizeSelector = [20, 50, 100];
    const [showPatientDetails, setShowPatientDetails] = useState(false);



    const [colDefs, setColDefs] = useState([
        {field: "id", hide: true},
        {field: "firstName", filter: true, floatingFilter: true},
        {field: "lastName", filter: true, floatingFilter: true},
        {field: "dateOfBirth", filter: true, floatingFilter: true},
        {field: "mobileNumber", filter: true, floatingFilter: true},
        {field: "email", filter: true, floatingFilter: true},
        {field: "accountType", hide: true},
        {field: "staffType", hide: true},
        {field: "status", hide: true},
        {field: "createdOn", filter: true, floatingFilter: true},
        {field: "address", filter: true, floatingFilter: true},
        {field: "gender", filter: true, floatingFilter: true}
    ]);

    useEffect(() => {
        const accountType = sessionStorage.getItem("accountType");
        if (accountType !== "staff") {
            toast.warning('You dont have access to this page.!', {position: "bottom-right"});
            navigate('/login');
        } else {
            fetchPatientsList();
        }
    }, []);

    const fetchPatientsList = () => {
        const token = sessionStorage.getItem("token");
        const requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json"
            }
        };

        fetch(API_BASE_URL + `/user/getPatientsList?token=${token}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setPatientsList(result.data);
            })
            .catch(error => console.error(error));
    };

    const handlePatientSelect = (patient) => {
        setSelectedPatient(patient);
        setShowPatientDetails(true); // Show patient details when a patient is selected
    };

    const handleUpload = () => {
        const token = sessionStorage.getItem("token");

        const formData = new FormData();
        formData.append("token", token);
        formData.append("file", file);
        formData.append("fileName", fileName);
        formData.append("id", selectedPatient.id);

        // You can perform your upload logic here, e.g., send a POST request with formData
        fetch(API_BASE_URL + "/file/upload", {
            method: "POST",
            body: formData
        })
            .then(response => {
                // Handle response
                toast.success('File uploaded successful.', {position: "bottom-right"});
                //Reset the form
                setFile(null);
                setFileName("");
            })
            .catch(error => {
                // Handle error
                toast.warning('File upload failed.', {position: "bottom-right"});
            });
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleNameChange = (event) => {
        setFileName(event.target.value);
    };

    const changePatient = () => {
        setShowPatientDetails(false); // Hide patient details to allow selecting a new patient
        setSelectedPatient(null); // Reset selected patient
    };




    return (
        <div>
            {!showPatientDetails ? (
                <div className="patient-selection">
                    <h1>Choose Patient</h1>
                    <div className="ag-theme-quartz">
                        <AgGridReact
                            rowData={patientsList}
                            columnDefs={colDefs}
                            onRowClicked={(event) => handlePatientSelect(event.data)}
                        />
                    </div>
                </div>
            ) : (
                <div className="add-patient-form-container">
                    <h2 className="form-heading">Patient Details</h2>
                    <div className="add-patient-form">
                        <div className="form-group">
                            <label htmlFor="firstName">First Name:</label>
                            <p>{selectedPatient.firstName}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name:</label>
                            <p>{selectedPatient.lastName}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="dateOfBirth">Date of Birth:</label>
                            <p>{selectedPatient.dateOfBirth}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobileNumber">Mobile Number:</label>
                            <p>{selectedPatient.mobileNumber}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <p>{selectedPatient.email}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="gender">Gender:</label>
                            <p>{selectedPatient.gender}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">Address:</label>
                            <p>{selectedPatient.address}</p>
                        </div>
                        <div className="file-upload">
                            <input type="file" accept="application/pdf" onChange={handleFileChange} />
                            <input type="text" placeholder="Enter file name" value={fileName}
                                   onChange={handleNameChange}/>
                            <button onClick={handleUpload}>Upload</button>
                            <button onClick={changePatient}>Change Patient</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}