import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaUser, FaCog, FaEnvelope } from "react-icons/fa";
import "./StaffHomePage.css"; // Import CSS file for styling
import { BsPeopleFill } from "react-icons/bs";
import { TiUserAdd } from "react-icons/ti";
import { AiFillFileAdd } from "react-icons/ai";
import { FaSignOutAlt } from "react-icons/fa";
import { IoDocuments, IoChatboxEllipses } from "react-icons/io5";




function StaffHomePage() {
    function handleLogout() {
        sessionStorage.clear();
    }

    return (
        <div className="staffHomePageMain">
            <h2>{sessionStorage.getItem('firstname')}'s Dashboard</h2>
            <div className="menu-container">
                <Link to="/patientsList" className="menu-card">
                    <BsPeopleFill className="icon"/>
                    <span>Patients</span>
                </Link>
                <Link to="/addNewPatient" className="menu-card">
                    <TiUserAdd className="icon" />
                    <span>Add New Patient</span>
                </Link>
                <Link to="/addFiles" className="menu-card">
                    <AiFillFileAdd className="icon"/>
                    <span>Add Files</span>
                </Link>
                <Link to="/staffViewFiles" className="menu-card">
                    <IoDocuments className="icon" />
                    <span>View Files</span>
                </Link>
                <Link to="/staffChat" className="menu-card">
                    <IoChatboxEllipses className="icon"/>
                    <span>Chat</span>
                </Link>
                <Link to="/login" onClick={handleLogout} className="menu-card">
                    <FaSignOutAlt className="icon" />
                    <span>Log out</span>
                </Link>

            </div>
        </div>
    );
}

export default StaffHomePage;