import React from "react";
import { Link } from "react-router-dom";
import {FaHome, FaUser, FaCog, FaEnvelope, FaSignOutAlt} from "react-icons/fa";
import "../StaffHomePage/StaffHomePage.css"; // Import CSS file for styling
import { BsPeopleFill } from "react-icons/bs";
import { TiUserAdd } from "react-icons/ti";
import { AiFillFileAdd } from "react-icons/ai";
import { IoDocuments, IoChatboxEllipses} from "react-icons/io5";







function PatientHomePage() {
    function handleLogout() {
        sessionStorage.clear();
    }

    return (
        <div className="staffHomePageMain">
            <h2>{sessionStorage.getItem("firstname")}'s Dashboard</h2>
            <div className="menu-container">
                <Link to="/patientViewFiles" className="menu-card">
                    <IoDocuments className="icon"/>
                    <span>View Files</span>
                </Link>
                <Link to="/patientChat" className="menu-card">
                    <IoChatboxEllipses className="icon"/>
                    <span>Chat box</span>
                </Link>
                <Link to="/login" onClick={handleLogout} className="menu-card">
                    <FaSignOutAlt className="icon" />
                    <span>Log out</span>
                </Link>

                {/*<Link to="/contact" className="menu-card">*/}
                {/*    <FaEnvelope className="icon" />*/}
                {/*    <span>Contact</span>*/}
                {/*</Link>*/}
            </div>
        </div>
    );
}

export default PatientHomePage;