import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../URL";

import {Bounce, toast} from "react-toastify";
import bcrypt from 'bcryptjs';

// import GoogleLogo from '../assets/googleIcon.png';


function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    let navigate = useNavigate();
    const [response, setResponse] = useState('');

    function handleDontHaveAnAccount() {
        navigate('/signup');
    }



    async function EmailSignIn(event) {
        event.preventDefault(); // Prevent the default form submission
        console.log(API_BASE_URL);
        try {
            // Create a request body object
            const requestBody = {
                mobilenumber: email, // Assuming email corresponds to mobilenumber in your backend
                password: password
            };
            // Send a POST request to the backend
            const request = await fetch(API_BASE_URL + '/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            if (!request.ok) {
                throw new Error('Failed to login');
            }
            const response = await request.json();
            console.log("Setting items in local storage");
            console.log(response.data);
            if (response.data) {
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('id', response.data.id);
                sessionStorage.setItem('accountType', response.data.accountType);
                sessionStorage.setItem('mobilenumber', response.data.mobileNumber);
                sessionStorage.setItem('stafftype', response.data.staffType);
                sessionStorage.setItem('firstname', response.data.firstName);
                sessionStorage.setItem('lastname', response.data.lastName);
                if (response.data.accountType === "staff") {
                    // Handle if accountType is staff
                    console.log('User is staff');
                    navigate('/staffHomePage');
                } else {
                    // Handle if accountType is not staff
                    console.log('User is patient');
                    navigate('/patientHomePage');
                }
            } else {
                console.error('Response data is not as expected:', response);
            }
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }

    function handleForgotPassword() {
    }

    function handleGoogleSignIn() {
    }

    return (
        <div className="login-container">
            <h1>Login</h1>
            <form className="login-form" onSubmit={EmailSignIn}>
                <div className="form-group">
                    <label htmlFor="email">Mobile Number</label>
                    <input
                        type="number"
                        id="number"
                        placeholder="Mobile Number"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Sign In</button>

            </form>

        </div>
    );
}

export default LoginPage;
