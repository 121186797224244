import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import "./NavbarHook.css";
//Have logo in public folder
import logo from "./aswiniHospitalsLogo.png";


const NavbarHook = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: "1150px" });

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMobileMenu = () => {
        if (isMobile) {
            setIsMenuOpen(false);
        }
    };

    const renderNavLinks = () => {
        const listClassName = isMobile ? "nav__list" : "nav__list__web";
        const linkClassName = "nav__link";
        const buttonClassName = "nav__cta";

        return (
            <ul className={listClassName}>
                <li>
                    <NavLink to="/" className={linkClassName} onClick={closeMobileMenu}>
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/news"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                    >
                        News
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/about"
                        className={linkClassName}
                        onClick={closeMobileMenu}
                    >
                        About
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/portal"
                        className={`${linkClassName} ${buttonClassName}`}
                        onClick={closeMobileMenu}
                    >
                        Dashboard
                    </NavLink>
                </li>
            </ul>
        );
    };

    return (
        <header className="header">
            <div className="nav__logo__parent">
                <NavLink to="/" className="nav__logo">
                    <img src={logo} alt="Aswini Hospitals"  className="logo-image"/>
                </NavLink>
            </div>
            <nav className="nav container">


                {isMobile && (
                    <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                        <IoMenu />
                    </div>
                )}

                {isMobile ? (
                    <div
                        className={`nav__menu  ${isMenuOpen ? "show-menu" : ""}`}
                        id="nav-menu"
                    >
                        {renderNavLinks()}
                        <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                            <IoClose />
                        </div>
                    </div>
                ) : (
                    renderNavLinks()
                )}
            </nav>
        </header>
    );
};

export default NavbarHook;