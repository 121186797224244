import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./PDFViewer.css";
import {API_BASE_URL} from "../URL";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ fileId }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileData, setFileData] = useState(null);

    useEffect(() => {
        fetchFileData();
    }, [fileId]);

    const fetchFileData = () => {
        const token = sessionStorage.getItem("token");
        fetch(API_BASE_URL+`/file/getFile?id=${fileId}&token=${token}`)
            .then(response => response.arrayBuffer())
            .then(data => {
                setFileData(new Uint8Array(data));
            })
            .catch(error => {
                console.error("Failed to fetch PDF file:", error);
            });
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handlePageChange = newPageNumber => {
        setPageNumber(newPageNumber);
    };

    const handleZoomIn = () => {
        // Implement zoom in logic here
    };

    const handleZoomOut = () => {
        // Implement zoom out logic here
    };

    return (
        <div className="pdf-frame">
            <div className="pdf-options">
                <button onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber <= 1}>
                    Previous Page
                </button>
                <span>
                    Page {pageNumber} of {numPages}
                </span>
                <button onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber >= numPages}>
                    Next Page
                </button>
                <button onClick={handleZoomIn}>Zoom In</button>
                <button onClick={handleZoomOut}>Zoom Out</button>
            </div>
            <div className="pdf-viewer">
                {fileData ? (
                    <Document
                        file={{ data: fileData }}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} width={800} />
                    </Document>
                ) : (
                    <p>Loading PDF...</p>
                )}
            </div>
        </div>
    );
}

export default PDFViewer;