import React, { useState } from "react";
import './AddNewPatient.css'; // Import CSS file for styling
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../URL";

function AddNewPatient() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [gender, setGender] = useState("");
    const [patientAdded, setPatientAdded] = useState(false); // New state to track if patient was added
    const navigate = useNavigate();
    const [patientId, setPatientId] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Check if all fields are filled
        if (!firstName || !lastName || !dateOfBirth || !mobileNumber || !email || !address || !gender) {
            toast.error("All fields are mandatory.", { position: "bottom-right" });
            return;
        }

        // Validate mobile number format
        const mobileNumberRegex = /^\d{13}$/;
        if (!mobileNumberRegex.test(mobileNumber)) {
            toast.error("Mobile number must be 13 digits.", { position: "bottom-right" });
            return;
        }

        const formData = {
            firstName,
            lastName,
            dateOfBirth,
            mobileNumber,
            email,
            gender,
            address
        };

        try {
            const queryParams = new URLSearchParams({
                token: sessionStorage.getItem("token"), // Append token as a query parameter
            });
            const request = await fetch(`${API_BASE_URL}/user/addNewPatient?${queryParams}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!request.ok) {
                throw new Error("Failed to add patient.");
            }
            const response = await request.json();
            if(response.message === "Patient Added Successfully")
            {
                toast.success("Patient added successfully.", {position: "bottom-right"});
                setPatientAdded(true); // Set patient added state to true
                setPatientId(response.data.id);
            }
            else{
                toast.error("Failed to add patient. Please try again later. "+response.message, {position: "bottom-right"});
            }
        } catch (error) {
            toast.error("Failed to add patient. Please try again later.", {position: "bottom-right"});
        }
    };

    const handleReset = () => {
        setFirstName("");
        setLastName("");
        setDateOfBirth("");
        setMobileNumber("");
        setEmail("");
        setGender("");
        setAddress("");
        setPatientAdded(false); // Reset patient added state
    };

    const handlePrintCredentials = async () => {
        try {
            const queryParams = new URLSearchParams({
                userId: patientId,
                token: sessionStorage.getItem("token"),
            });
            const request = await fetch(`${API_BASE_URL}/file/getCredentialFile?${queryParams}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/pdf",
                },
            });

            if (!request.ok) {
                throw new Error("Failed to fetch credentials.");
            }
            const blob = await request.blob();
            const url = window.URL.createObjectURL(blob);

            const newWindow = window.open(url);
            if (newWindow) {
                newWindow.onload = () => {
                    newWindow.print();
                    window.URL.revokeObjectURL(url);
                };
            } else {
                throw new Error("Failed to open new window. Please allow pop-ups and try again.");
            }
        } catch (error) {
            toast.error("Failed to fetch credentials. Please try again later.", { position: "bottom-right" });
        }
    };


    return (
        <div className="add-patient-form-container">
            <h2 className="form-heading">Add New Patient</h2>
            <form className="add-patient-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="firstName">First Name:</label>
                    <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name:</label>
                    <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label htmlFor="dateOfBirth">Date of Birth:</label>
                    <input type="date" id="dateOfBirth" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number:</label>
                    <input type="text" id="mobileNumber" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label htmlFor="gender">Gender:</label>
                    <select id="gender" value={gender} onChange={(e) => setGender(e.target.value)} required>
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address:</label>
                    <textarea id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                </div>
                {!patientAdded ? (
                    <button className="submit-button" type="submit">Add Patient</button>
                ) : (
                    <>
                        <button className="print-button" type="button" onClick={handlePrintCredentials}>Print Credentials</button>
                        <button className="reset-button" type="button" onClick={handleReset}>Reset Form</button>
                    </>
                )}
            </form>
        </div>
    );
}

export default AddNewPatient;
