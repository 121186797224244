import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import NavbarHook from "./NavbarHook/NavbarHook";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import About from "./pages/About/About";
import Portal from "./pages/Portal";
import Footer from "./pages/Footer/Footer";
import LoginPage from "./pages/LoginPage/LoginPage";
import PatientsList from "./pages/PatientList/PatientsList";
import PatientPortal from "./pages/PatientViewFiles/PatientViewFiles";
import { ToastContainer} from 'react-toastify';
import { TokenProvider } from './TokenProvider';
import 'react-toastify/dist/ReactToastify.css';
import AddNewPatient from "./pages/AddNewPatient/AddNewPatient";
import StaffHomePage from "./pages/StaffHomePage/StaffHomePage";
import AddFiles from "./pages/AddFiles/AddFiles";
import PatientHomePage from "./pages/PatientHomePage/PatientHomePage";
import PatientViewFiles from "./pages/PatientViewFiles/PatientViewFiles";
import StaffViewFiles from "./pages/StaffViewFiles/StaffViewFiles";
import PatientChat from "./pages/PatientChat/PatientChat";
import StaffChat from "./pages/StaffChat/StaffChat";



const App = () => {
  return (
      <Router>
        <TokenProvider />
        {/* <Navbar /> */}
        <NavbarHook />
        <ToastContainer />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<News />} />
            <Route path="/about" element={<About />} />
            <Route path="/portal" element={<Portal />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/patientsList" element={<PatientsList />} />
            <Route path="/patientViewFiles" element={<PatientViewFiles />} />
            <Route path="/addNewPatient" element={<AddNewPatient />} />
            <Route path="/staffHomePage" element={<StaffHomePage />} />
            <Route path="/addFiles" element={<AddFiles />} />
            <Route path="/patientHomePage" element={<PatientHomePage />} />
            <Route path="/staffViewFiles" element={<StaffViewFiles />} />
            <Route path="/patientChat" element={<PatientChat />} />
            <Route path="/staffChat" element={<StaffChat />} />


            {/* Define other routes that you need*/}
          </Routes>
        </main>
        {/*<Footer />*/}

      </Router>
  );
};

export default App;