import React, { createContext, useContext, useState, useEffect } from 'react';

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [tokenExpiration, setTokenExpiration] = useState(null);

    useEffect(() => {
        const storedToken = sessionStorage.getItem('token');
        const storedTokenExpiration = sessionStorage.getItem('tokenExpiration');

        if (storedToken && storedTokenExpiration) {
            setToken(storedToken);
            setTokenExpiration(parseInt(storedTokenExpiration));
        }
    }, []);

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (token && tokenExpiration && Date.now() > tokenExpiration) {
                // Token has expired
                setToken(null);
                setTokenExpiration(null);
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('tokenExpiration');
                // Redirect to login page
                window.location.href = '/login'; // Change this to your login page URL
            }
        };

        const tokenExpirationTimer = setInterval(checkTokenExpiration, 1000);

        return () => clearInterval(tokenExpirationTimer);
    }, [token, tokenExpiration]);

    const updateToken = (newToken, expirationTime) => {
        setToken(newToken);
        setTokenExpiration(expirationTime);
        sessionStorage.setItem('token', newToken);
        sessionStorage.setItem('tokenExpiration', expirationTime.toString());
    };

    return (
        <TokenContext.Provider value={{ token, updateToken }}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => useContext(TokenContext);