import React, {useEffect, useMemo, useState} from "react";
import "./PatientViewFiles.css";
import { Bounce, toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react"; // Import AG Grid
import { useNavigate } from "react-router-dom";
import PDFViewer from "../PDFViewer";
import { API_BASE_URL } from "../../URL"; // Import PDF viewer component
import "ag-grid-community/styles/ag-grid.css"; // AG Grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // AG Grid theme CSS
import { FaArrowRightFromBracket } from "react-icons/fa6";
import { IoIosClose } from "react-icons/io";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



function PatientViewFiles() {
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const pagination = true;
    const paginationPageSize = 10;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileData, setFileData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const token = sessionStorage.getItem("token");
    const documentFile = useMemo(() => ({ data: fileData }), [fileData]);



    useEffect(() => {
        const token = sessionStorage.getItem("token");
        fetchFilesDetails(token);
    }, []);

    const fetchFileData = (fileId) => {
        fetch(API_BASE_URL + `/file/getFile?id=${fileId}&token=${token}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch PDF file');
                }
                return response.arrayBuffer();
            })
            .then(data => {
                setFileData(new Uint8Array(data));
                setIsLoading(false); // Set isLoading to false when data is fetched successfully
            })
            .catch(error => {
                console.error("Failed to fetch PDF file:", error);
                setIsLoading(false); // Set isLoading to false on error as well
            });
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handlePageChange = newPageNumber => {
        setPageNumber(newPageNumber);
    };

    const fetchFilesDetails = (token) => {
       fetch(API_BASE_URL + `/file/getFilesDetails?token=${token}`, {
            method: "POST",
            redirect: "follow",
        })
            .then((response) => response.json())
            .then((data) => {
                setFiles(data.data.map((item) => ({ id: item[0], name: item[1], timestamp: item[2] })));
            })
            .catch((error) => {
                console.error(error);
                toast.error("Failed to fetch files details", {position: "bottom-right"});
            });
    };

    const handleViewButtonClick = (fileId) => {
        // Open PDF viewer dialog
        setSelectedFile(fileId);
        fetchFileData(fileId);
    };

    const columnDefs = [
        { headerName: "File Name", field: "name" },
        { headerName: "Timestamp", field: "timestamp" },
        {
            headerName: "Action",
            cellRenderer: (params) => (
                // <button onClick={() => handleViewButtonClick(params.data.id)}>View</button>
                <FaArrowRightFromBracket onClick={() => handleViewButtonClick(params.data.id)} />
            ),
            width: 100, // Set a fixed width for the action column
            height: 50,
            cellStyle: { textAlign: "center" }, // Center-align the button
        },
    ];

    function handleDialogBoxClose() {
        setSelectedFile(null);
    }

    return (
        <div className="ag-theme-quartz" style={{ height: 400, width: "95%" }}>
            <AgGridReact
                rowData={files}
                columnDefs={columnDefs}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
            />
            {/* Render PDFViewer if selectedFile is not null */}
            {selectedFile && (
                <div className="pdf-viewer-dialog">
                    {/*<PDFViewer fileId={selectedFile} />*/}
                    <div className="dialog-box-header">
                        <div>
                            <h3>{selectedFile && files.find(file => file.id === selectedFile)?.name}, {selectedFile && files.find(file => file.id === selectedFile)?.timestamp}</h3>
                        </div>
                        <IoIosClose onClick={handleDialogBoxClose} className="close-icon"/>
                    </div>
                    <div className="pdf-viewer">

                        {fileData && !isLoading ? (
                            <Document
                                file={documentFile}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} width={800}/>
                            </Document>
                        ) : (
                            <p>Loading PDF...</p>
                        )}
                    </div>

                </div>
            )}
        </div>
    );
}

export default PatientViewFiles;