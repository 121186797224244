import React, { useEffect, useState,useMemo } from "react";
import {API_BASE_URL} from "../../URL";
import {AgGridReact} from "ag-grid-react";
import './StaffViewFiles.css';
import {Bounce, toast} from "react-toastify";
import {FaArrowRightFromBracket} from "react-icons/fa6";
import { Document, Page, pdfjs } from "react-pdf";
import {IoIosClose} from "react-icons/io";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function StaffViewFiles() {
    const [patientsList, setPatientsList] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const pagination = true;
    const paginationPageSize = 20;
    const paginationPageSizeSelector = [20, 50, 100];
    const [showPatientFiles, setShowPatientFiles] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileData, setFileData] = useState(null);
    const token = sessionStorage.getItem("token");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const documentFile = useMemo(() => ({ data: fileData }), [fileData]);








    const [colDefs, setColDefs] = useState([
        {field: "id", hide: true},
        {field: "firstName", filter: true, floatingFilter: true},
        {field: "lastName", filter: true, floatingFilter: true},
        {field: "dateOfBirth", filter: true, floatingFilter: true},
        {field: "mobileNumber", filter: true, floatingFilter: true},
        {field: "email", filter: true, floatingFilter: true},
        {field: "accountType", hide: true},
        {field: "staffType", hide: true},
        {field: "status", hide: true},
        {field: "createdOn", filter: true, floatingFilter: true},
        {field: "address", filter: true, floatingFilter: true},
        {field: "gender", filter: true, floatingFilter: true}
    ]);

    useEffect(() => {
        fetchPatientsList();
    }, []);

    const fetchPatientsList = () => {

        const requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json"
            }
        };

        fetch(API_BASE_URL + `/user/getPatientsList?token=${token}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setPatientsList(result.data);
            })
            .catch(error => console.error(error));
    };

    const handlePatientSelect = (patient) => {
        setSelectedPatient(patient.data);
        setShowPatientFiles(true); // Show patient details when a patient is selected

        fetchFilesDetails(patient.data.id);
    };

    const columnDefsFiles = [
        { headerName: "File Name", field: "name" },
        { headerName: "Timestamp", field: "timestamp" },
        {
            headerName: "Action",
            cellRenderer: (params) => (
                // <button onClick={() => handleViewButtonClick(params.data.id)}>View</button>
                <FaArrowRightFromBracket onClick={() => handleViewButtonClick(params.data.id)} />
            ),
            width: 100, // Set a fixed width for the action column
            height: 50,
            cellStyle: { textAlign: "center" }, // Center-align the button
        },
    ];

    const handleViewButtonClick = (fileId) => {
        // Open PDF viewer dialog
        setSelectedFile(fileId);
        fetchFileData(fileId);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handlePageChange = newPageNumber => {
        setPageNumber(newPageNumber);
    };

    const fetchFileData = (fileId) => {
        fetch(API_BASE_URL + `/file/getFile?id=${fileId}&token=${token}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch PDF file');
                }
                return response.arrayBuffer();
            })
            .then(data => {
                setFileData(new Uint8Array(data));
                setIsLoading(false); // Set isLoading to false when data is fetched successfully
            })
            .catch(error => {
                console.error("Failed to fetch PDF file:", error);
                setIsLoading(false); // Set isLoading to false on error as well
            });
    };

    const fetchFilesDetails = (patientId) => {

        fetch(API_BASE_URL + `/file/getFilesDetailsById?token=${token}&id=${patientId}`, {
            method: "POST",
            redirect: "follow",
        })
            .then((response) => response.json())
            .then((data) => {
                setFiles(data.data.map((item) => ({ id: item[0], name: item[1], timestamp: item[2] })));
            })
            .catch((error) => {
                console.error(error);
                toast.error("Failed to fetch files details", {position: "bottom-right"});
            });
    };

    function handleDialogBoxClose() {
        setSelectedFile(null);
    }

    return (
        <div>
            <h1>Staff View Files</h1>

            {!showPatientFiles && ( // Render patients list if a patient is not selected
                <div className="ag-theme-quartz" style={{ height: 400, width: "95%" }}>
                    <AgGridReact
                        rowData={patientsList}
                        columnDefs={colDefs}
                        pagination={pagination}
                        paginationPageSize={paginationPageSize}
                        onRowClicked={handlePatientSelect} // Handle patient selection on row click
                    />
                </div>
            )}

            {showPatientFiles && selectedPatient && ( // Render patient details if a patient is selected
                <div>
                    <div className="ag-theme-quartz" style={{ height: 400, width: "95%" }}>
                        <AgGridReact
                            rowData={files}
                            columnDefs={columnDefsFiles}
                            pagination={pagination}
                            paginationPageSize={paginationPageSize}
                        />
                    </div>
                </div>
            )}

            {selectedFile && (
                <div className="pdf-viewer-dialog">
                    <div className="dialog-box-header">
                        <div>
                            <h3>{selectedFile && files.find(file => file.id === selectedFile)?.name}, {selectedFile && files.find(file => file.id === selectedFile)?.timestamp}</h3>
                        </div>
                        <IoIosClose onClick={handleDialogBoxClose} className="close-icon"/>
                    </div>
                    <div className="pdf-viewer">
                        {!isLoading && fileData ? ( // Check if isLoading is false and fileData is available
                            <Document
                                file={documentFile}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} width={800}/>
                            </Document>
                        ) : (
                            <p>Loading PDF...</p>
                        )}
                    </div>
                </div>
            )}

        </div>
    );
}