import React from "react";

const About = () => {
    return (
        <div>
            <p>About</p>
        </div>
    );
};

export default About;
