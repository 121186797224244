import React, { useState, useEffect } from "react";
import './PatientChat.css'; // Import CSS file for styling
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../URL";

function PatientChat() {
    // State variables for dropdowns
    const [role, setRole] = useState("");
    const [staffList, setStaffList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const fromId = sessionStorage.getItem("id");
    const [showChatBox, setShowChatBox] = useState(false);
    const [inboxMessages, setInboxMessages] = useState([]);


    useEffect(() => {
        const fetchInboxPreview = async () => {
            try {
                const request = await fetch(`${API_BASE_URL}/chat/getInboxPreview?token=${token}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const response = await request.json();
                setInboxMessages(response.data);
            } catch (error) {
                console.error("Error fetching inbox preview:", error);
                toast.error("Failed to fetch inbox preview. Please try again later.", {
                    position: "bottom-right",
                });
            }
        };

        fetchInboxPreview(); // Call fetchInboxPreview here
        fetchStaffList(); // Also fetch staff list here if needed
    }, []);

    const fetchStaffList = () => {
        const requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(API_BASE_URL+`/user/getStaffList?token=${token}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setStaffList(result.data);
            })
            .catch(error => console.error(error));
    };


    const fetchChatHistory = async () => {
        try {
            const request = await fetch(`${API_BASE_URL}/chat/getChatHistory?token=${token}&toId=${selectedStaff}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const response = await request.json();
            response.data.sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp));
            setChatHistory(response.data);
            console.log(response);
            const chatHistoryContainer = document.querySelector(".chat-box-container");
            chatHistoryContainer.scrollTop = chatHistoryContainer.scrollHeight;
        } catch (error) {
            console.error("Error fetching chat history:", error);
            toast.error("Failed to fetch chat history. Please try again later.", {
                position: "bottom-right",
            });
        }
    };


    const filteredStaffList = staffList.filter(staff => {
        if (role === "doctor") {
            return staff.staffType === "doctor";
        } else if (role === "staff") {
            return staff.staffType === "staff";
        } else if(role === "admin"){
            return staff.staffType === "admin";
        }
        return true;
    });


    useEffect(() => {
        if (selectedStaff) {
            fetchChatHistory();
        }
    }, [selectedStaff]);


    const sendMessage = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/chat/saveChat?token=${token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    toId: selectedStaff,
                    message: currentMessage,
                }),
            });
            const data = await response;
            await fetchChatHistory();
            setCurrentMessage("");
        } catch (error) {
            console.error("Error sending message:", error);
            toast.error("Failed to send message. Please try again later.", {
                position: "bottom-right",
            });
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            sendMessage();
        }
    };


    function goToInbox() {
        setShowChatBox(false);
    }

    function goToChatBox(fromId) {
        setShowChatBox(true);
        setSelectedStaff(fromId); // Pass the fromId to setSelectedStaff
    }

    function onSelectMessage(message) {

    }

    const renderInboxMessages = () => {
        return inboxMessages.map((messageData, index) => {
            const message = messageData[0];
            const senderFirstName = messageData[1];
            const senderLastName = messageData[2];
            const time = new Date(message.timeStamp).toLocaleTimeString();

            return (
                <div key={index} className="inbox-message" onClick={() => goToChatBox(message.fromId)}>
                    <div className="sender">{`${senderFirstName} ${senderLastName}`}</div>
                    <div className="preview">{message.message}</div>
                    <div className="time">{time}</div>
                </div>
            );
        });
    };

    const renderInbox = () => {
        return (
            <div className="inbox-pane-container">
                <div className="inbox-card">
                    <h2 className="inbox-title">Inbox</h2>
                    <div className="inbox-messages">
                        {renderInboxMessages()}
                        <button onClick={startMessage}>New Message</button>
                    </div>
                </div>
            </div>
        )
    }

    const renderChatHistory = () => {
        return (

            <div className="chat-history-container">
                <h3 className="chat-history-title">Chat History</h3>

                <div className="dropdowns-container">
                    <div className="form-group">
                        <select id="role" value={role} onChange={(e) => setRole(e.target.value)} required>
                            <option value="">Role</option>
                            <option value="doctor">Doctor</option>
                            <option value="staff">Staff</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <select id="selectedStaff" value={selectedStaff}
                                onChange={(e) => setSelectedStaff(e.target.value)} required>
                            <option value="">Person</option>
                            {filteredStaffList.map((staff) => (
                                <option key={staff.id} value={staff.id}>{staff.firstName} {staff.lastName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <button onClick={goToInbox}>Inbox</button>
                    </div>
                </div>
                <div className="chat-history">
                    <ul>
                        {chatHistory.map((message) => (
                            <li key={message.id}
                                className={message.toId === parseInt(selectedStaff) ? "sent" : "received"}>
                                <div>
                                    <span>{message.message}</span>
                                    <br/>
                                    <span className="timestamp">
                                        {new Date(message.timeStamp).toLocaleString()}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="message-input-container">
                    <input
                        type="text"
                        placeholder="Type your message..."
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <button onClick={sendMessage}>Send</button>
                </div>
            </div>
        );
    };

    function startMessage() {
        setShowChatBox(true);
    }

    return (
        <div className="chat-box-container">
            {showChatBox ? renderChatHistory() :  renderInbox()}
        </div>
    );
}

export default PatientChat;