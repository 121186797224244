import React from "react";
import "./Home.css";

function Home() {
    return (
        <div className="parentDiv">
            <div className="top">
                <div className="homeBackground">
                    <div className="home-container">
                        <div className="overlay"></div>
                        <div className="content">
                            <h1>Welcome to Aswini Hospitals</h1>
                            <p>Providing top-notch healthcare services</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;